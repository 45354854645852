.header {
    margin-left: 10vw;
    margin-right: 10vw;
    background-color: white;
    display: flex;
    align-items: center;
    min-width: fit-content;
}

.mobile__header {
    background-color: white;
    display: flex;
    align-items: center;
    min-width: fit-content;
}

.logo {
    text-align: right;
}